import { mergeAttributes, Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import { uniqueId } from "lodash-es";
import TableOfContextNodeTemplate
    from "@/tiptap/extensions/TableOfContents/components/TableOfContextNodeTemplate.vue";

export const tableOfContexts = Node.create({
    name: 'table-of-contexts',

    group: 'block',

    addAttributes() {
        return {
            id: {
                default: uniqueId(),
            },
            data: {
                default: null,
            },

            isDraggable: {
                default: true,
                renderHTML: (attributes) => {
                    return {};
                }
            },
            inline: {
                default: false,
            },
        }
    },

    parseHTML() {
        return [{
            tag: 'table-of-contexts',
        }]
    },

    renderHTML({ HTMLAttributes }) {
        return ['table-of-contexts', mergeAttributes(HTMLAttributes)]
    },

    addNodeView() {
        return VueNodeViewRenderer(TableOfContextNodeTemplate);
    },

    addCommands() {
        return {
            setTableOfContext: (options) => ({ commands, tr, dispatch, chain }) => {
                const { selection } = tr

                const node = this.type.create(options)

                if (dispatch) {
                    tr.replaceRangeWith(selection.from, selection.to, node)
                }

                return true;
            },

        }
    },
});

export default tableOfContexts;
