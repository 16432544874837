<template>
    <div v-if="!loading" class="login-to-the-room min-h-screen">
        <Toast/>
        <RoomView v-if="!shouldLogin"/>
        <Dialog v-model:visible="shouldLogin" header="Login to the room" :style="{ width: '500px' }">

            <div class="flex gap-2 mt-2">
                <BaseAvatar
                    class="h-12 w-12 rounded-full shadow-md shadow-gray-900"
                    :src="room.tenant.avatar"
                    :name="room.tenant.name"
                />
                <BaseAvatar
                    class="h-12 w-12 rounded-full shadow-md shadow-gray-900"
                    :src="room.account?.avatar"
                    :name="room.account?.name || ''"
                />
            </div>
            <p v-if="success" class="mt-4 bg-green-100 rounded-md p-4 text-green-600">
                <span class="font-semibold">Check your email for a verification link.</span><br />
                <span>We sent an email to {{success}}. The link expires in 24 hours</span>
            </p>
            <BaseFormCard @submit="loginExternal" :form="form" class="mt-4">
                <FormField v-slot="{ componentField }" name="email">
                    <FormItem v-auto-animate>
                        <FormLabel>Email</FormLabel>
                        <FormControl>
                            <Input type="email" name="email" placeholder="Enter your email" v-bind="componentField"/>
                        </FormControl>
                        <FormMessage/>
                    </FormItem>
                </FormField>
                <FormField v-slot="{ componentField }" name="password"
                           v-if="room.restricted_password && room.share_type === 'restricted'">
                    <FormItem v-auto-animate>
                        <FormLabel>Password</FormLabel>
                        <FormControl>
                            <Input type="password" placeholder="Enter the password" name="email"
                                   v-bind="componentField"/>
                        </FormControl>
                        <FormMessage/>
                    </FormItem>
                </FormField>

                <template #footer>
                    <div class="flex justify-center gap-2 mt-4">
                        <Button type="submit" size="small" label="Continue" class="w-full" :loading="form.processing">
                            Continue
                        </Button>
                    </div>
                </template>
            </BaseFormCard>
        </Dialog>
    </div>
</template>
<script setup>
import { usePage } from '@inertiajs/vue3';
import RoomView from '../RoomDetail/components/RoomView.vue';
import { usePusher } from '../../hooks/usePusher.js';
import { onMounted, ref } from 'vue';
import BaseAvatar from '@/Components/forms/BaseAvatar.vue';
import { displayErrorMessagesAsToast } from '../../utils/errorUtils.js';
import Button from '../../tiptap/components/ui/button/Button.vue';
import * as yup from 'yup';
import useValidatedForm from '../../hooks/useValidatedFormNew';
import { useAuth } from '../../hooks/useAuth.js';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/Components/ui/form'
import { Input } from '@/Components/ui/input'
import BaseFormCard from '@/Components/forms/BaseFormCard.vue';
import { useToast } from 'primevue/usetoast';
import Toast from "primevue/toast";

usePusher();
const loading = ref(true);
const page = usePage();
const shouldLogin = ref(page.props.login);
const room = ref(page.props.room);
const {user} = useAuth();
const toast = useToast()

const url = window.location.href;
const success = ref(false);
const urlParams = new URLSearchParams(window.location.search);
const schema = yup.object().shape({
    email: yup.string().required('Please insert the email'),
    password: yup.string(),
});

const form = useValidatedForm({
    email: urlParams.get('email') || '',
    password: '',
    room_id: room.value.id,
    token: urlParams.get('token') || null,
}, schema);

function loginExternal() {
    form.post('/login-external', {
        onSuccess: (data) => {
            if (data.props.flash?.message) {
                success.value = form.data().email;
                form.vee.resetForm('email', '');
            } else {
                var newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;
                window.location.replace(newUrl);
            }
        },
        onError: (err) => {
            loading.value = false;
            displayErrorMessagesAsToast(err, toast)
        },
    })
}

onMounted(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    const email = params.get('email');
    const shareType = room.value.share_type;

    if (token && email) {
        form.email = email;
        form.token = token;
        loginExternal();

        return;
    }

    if (shareType === 'public' && user === null) {
        form.email = 'anonymous';
        loginExternal();

        return;
    }

    loading.value = false;
})

</script>
<style lang="scss">
.login-to-the-room .p-dialog-mask, .login-to-the-room {
    background-color: #E1E5EB
}
</style>
