<template>
    <RoomCard>
        <template #header>
            <BaseAvatar
                v-if="room.account"
                :src="room.account?.avatar"
                :name="room.account.name"
                class="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
            />
            <RoomCardTitle>{{ room.name }}</RoomCardTitle>
        </template>
        <RoomCardRow v-if="room.start_date" label="Start Date" :value="formatDate(room.start_date, 'MMM dd yyyy')"/>
        <RoomCardRow v-if="room.end_date" label="End Date" :value="formatDate(room.end_date, 'MMM dd yyyy')"/>
        <RoomCardRow v-if="room.opportunity_name" label="Opportunity Name" :value="room.opportunity_name"/>
        <RoomCardRow v-if="room.opportunity_value" label="Opportunity Value" :value="opportunity"/>
        <RoomCardRow label="Technical Win" >
            <FormField v-slot="{ componentField }" name="status">
                <FormItem v-auto-animate class="w-64">
                    <FormControl>
                        <Select v-bind="componentField" :model-value="technicalWin" @update:model-value="onTechnicalWinChange">
                            <FormControl>
                                <SelectTrigger>
                                    <SelectValue placeholder="Select technical win"/>
                                </SelectTrigger>
                            </FormControl>
                            <SelectContent >
                                <SelectGroup>
                                    <SelectItem v-for="model in technicalWinOptions" :value="model.value">
                                        <div class="text-sm">{{ model.name }}</div>
                                    </SelectItem>
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </FormControl>
                    <FormMessage/>
                </FormItem>
            </FormField>
        </RoomCardRow>
        <RoomCardRow label="Temperature" >
            <FormField v-slot="{ componentField }" name="status">
                <FormItem v-auto-animate class="w-64">
                    <FormControl>
                        <Select v-bind="componentField" :model-value="temperatureValue" @update:model-value="onTemperatureChange">
                            <FormControl>
                                <SelectTrigger>
                                    <SelectValue placeholder="Select temperature"/>
                                </SelectTrigger>
                            </FormControl>
                            <SelectContent >
                                <SelectGroup>
                                    <SelectItem v-for="model in temperatures" :value="model.value">
                                        <div class="flex items-center gap-2">
                                            <div class="w-4 h-4 rounded-full" :style="{'background-color': model.color}"/>
                                            <div class="text-sm">{{ model.name }}</div>
                                        </div>
                                    </SelectItem>
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </FormControl>
                    <FormMessage/>
                </FormItem>
            </FormField>
        </RoomCardRow>

        <RoomCardRow label="Room Status">
            <FormField v-slot="{ componentField }" name="status">
                <FormItem v-auto-animate class="w-64">
                    <FormControl>
                        <Select v-bind="componentField" :model-value="status" @update:model-value="onChangeStatus">
                            <FormControl>
                                <SelectTrigger>
                                    <SelectValue placeholder="Select room status"/>
                                </SelectTrigger>
                            </FormControl>
                            <SelectContent >
                                <SelectGroup>
                                    <SelectItem v-for="model in roomStatusOptions" :value="model.value">
                                        <div class="text-sm">{{ model.label }}</div>
                                    </SelectItem>
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </FormControl>
                    <FormMessage/>
                </FormItem>
            </FormField>
        </RoomCardRow>
    </RoomCard>
</template>

<script setup lang="ts">

import BaseAvatar from "../../../Components/forms/BaseAvatar.vue";
import RoomCard from "./RoomCard.vue";
import RoomCardRow from "./RoomCardRow.vue";
import { computed, ref } from "vue";
import axios from "axios";
import { displayErrorMessagesAsToast } from "../../../utils/errorUtils";
import { formatDate } from "date-fns";
import RoomCardTitle from "./RoomCardTitle.vue";
import { useRoomStatusOptions } from "../../../hooks/useRoomStatusOptions";
import { FormControl, FormField, FormItem, FormMessage } from '@/Components/ui/form'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/Components/ui/select'

const props = defineProps({
    room: {
        type: Object,
    }
})
const {roomStatusOptions} = useRoomStatusOptions();
const temperatures = [{
    name: 'Satisfied',
    color: '#4ECB71',
    value: 'satisfied',
}, {
    name: 'Neutral',
    color: '#FFD233',
    value: 'neutral',
}, {
    name: 'Dissatisfied',
    color: '#FF9A62',
    value: 'dissatisfied',
}, {
    name: 'Very Dissatisfied',
    color: '#F24E1E',
    value: 'very_dissatisfied',
}]
const technicalWinOptions = [
    { name: 'Yes', value: 1 },
    { name: 'No', value: 0 },
]
const temperatureValue = ref<string>(props.room.temperature || '')
const technicalWin = ref<string>(props.room.technical_win)
const temperatureValueObj = computed(() => {
    return temperatures.find(temperature => temperature.value === temperatureValue.value)
})

const opportunity = computed(() => {
    return `$${props.room.opportunity_value}`;
})

const status = ref<string>(props.room.status)

function onTemperatureChange(e) {
    temperatureValue.value = e;
    axios.post(`/rooms/${props.room.ulid}/temperature`, {
        temperature: temperatureValue.value,
    }).then(() => {
        //this.$toast.success('Temperature updated successfully');
    }).catch((error) => {
        displayErrorMessagesAsToast(error, toast);
    });
}
function onChangeStatus(e) {
    status.value = e;
    axios.post(`/rooms/${props.room.ulid}/status`, {
        status: status.value,
    }).then(() => {
        //this.$toast.success('Temperature updated successfully');
    }).catch((error) => {
        displayErrorMessagesAsToast(error);
    });
}

function onTechnicalWinChange(e) {
    technicalWin.value = e;

    axios.post(`/rooms/${props.room.ulid}/technical-win`, {
        technical_win: technicalWin.value,
    }).then(() => {
    }).catch((error) => {
        displayErrorMessagesAsToast(error);
    });

}



</script>
<style scoped lang="scss">

</style>
