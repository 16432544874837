<script setup lang="ts">
import { LifeBuoy, LogOut, Settings, CreditCard} from 'lucide-vue-next'

import { Button } from '@/Components/ui/button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '@/Components/ui/dropdown-menu'
import BaseAvatar from "../forms/BaseAvatar.vue";
import { useAuth } from "../../hooks/useAuth.js";
import { router } from '@inertiajs/vue3';

const { user, isAdmin } = useAuth()

function openSupport() {
    window.open('https://roombriks.com/support', '_blank')
}

const props = defineProps({
    isSidebarVisible: {
        type: Boolean
    }
})
</script>

<template>
    <DropdownMenu>
        <DropdownMenuTrigger as-child>
            <Button variant="ghost" class="w-full px-1.5 h-12 ">
                <div :class="{
                    'gap-3 flex items-start  w-full text-sm font-medium': true,
                    'justify-center ml-2': !props.isSidebarVisible
                }">
                    <BaseAvatar
                        class="h-6 w-6 rounded-full"
                        :src="user.tenant.avatar"
                        :name="user.tenant.name"
                    />
                    <span v-if="props.isSidebarVisible">
                        {{ user.tenant.name }}
                    </span>
                </div>
            </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent class="w-56">
            <DropdownMenuLabel>
                <p>{{ user.name }}</p>
                <p class="text-gray-500 font-light">{{ user.email }}</p>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
                <DropdownMenuItem @click="() => {router.visit('/company')}">
                    <Settings class="mr-2 h-4 w-4 text-gray-950" />
                    <span>Settings</span>
                </DropdownMenuItem>
                <DropdownMenuItem v-if="isAdmin" @click="() => {router.visit('/billing')}">
                    <CreditCard class="mr-2 h-4 w-4 text-gray-950" />
                    <span>Billing</span>
                </DropdownMenuItem>
                <DropdownMenuItem @click="() => {router.visit('/profile')}">
                    <BaseAvatar
                        class="h-4 w-4 rounded-full text-gray-950"
                        :src="user.avatar"
                        :name="user.tenant.name"
                    />
                    <span class="ml-2">Profile</span>
                </DropdownMenuItem>
            </DropdownMenuGroup>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
                <DropdownMenuItem @click="() => openSupport()">
                    <LifeBuoy class="mr-2 h-4 w-4 text-gray-950" />
                    <span>Support</span>
                </DropdownMenuItem>
            </DropdownMenuGroup>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
                <DropdownMenuItem @click="() => {router.visit('/logout')}">
                    <LogOut class="mr-2 h-4 w-4 text-gray-950" />
                    <span>Sign out</span>
                </DropdownMenuItem>
            </DropdownMenuGroup>
        </DropdownMenuContent>
    </DropdownMenu>
</template>
