<template>
    <div class="w-full">
        <div class="flex gap-2 items-center py-4 items-center">
            <InputWithIcon
                :icon="SearchIcon"
                class="max-w-sm"
                placeholder="Search"
                :model-value="table.getColumn('name')?.getFilterValue() as string"
                @update:model-value="table.getColumn('name')?.setFilterValue($event)"
            />
            <Button variant="outline" @click="(e) => {
                showTableFilters = true
            }">
                <FilterIcon class="h-4 w-4 text-gray-950" />
            </Button>
<!--            <DropdownMenu>-->
<!--                <DropdownMenuTrigger as-child>-->
<!--                    <Button variant="outline">-->
<!--                        <FilterIcon class="h-4 w-4" />-->
<!--                    </Button>-->
<!--                </DropdownMenuTrigger>-->
<!--                <DropdownMenuContent align="end">-->
<!--                    <DropdownMenuCheckboxItem-->
<!--                        v-for="column in table.getAllColumns().filter((column) => column.getCanHide())"-->
<!--                        :key="column.id"-->
<!--                        class="capitalize"-->
<!--                        :checked="column.getIsVisible()"-->
<!--                        @update:checked="(value) => {-->
<!--                          column.toggleVisibility(!!value)-->
<!--                        }"-->
<!--                    >-->
<!--                        {{ column.id }}-->
<!--                    </DropdownMenuCheckboxItem>-->
<!--                </DropdownMenuContent>-->
<!--            </DropdownMenu>-->
            <Button variant="outline">
                <Grid2X2Icon class="h-4 w-4 text-gray-950" />
            </Button>
            <div class="flex w-full justify-end ">
                <div>
<!--                    <Link href="/rooms/create" as="button">-->
                        <CreateButton @click="onCreateRoom">
                            Create Room
                        </CreateButton>
<!--                    </Link>-->
                </div>
            </div>
        </div>
        <div class="rounded-md border">
            <Table>
                <TableHeader class="bg-gray-25">
                    <TableRow v-for="headerGroup in table.getHeaderGroups()" :key="headerGroup.id">
                        <TableHead v-for="header in headerGroup.headers" :key="header.id">
                            <FlexRender v-if="!header.isPlaceholder" :render="header.column.columnDef.header" :props="header.getContext()" />
                        </TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    <template v-if="table.getRowModel().rows?.length">
                        <template v-for="row in table.getRowModel().rows" :key="row.id">
                            <TableRow :data-state="row.getIsSelected() && 'selected'" @click="(e) => onClickTableRow(row)" class="cursor-pointer">
                                <TableCell v-for="cell in row.getVisibleCells()" :key="cell.id">
                                    <FlexRender :render="cell.column.columnDef.cell" :props="cell.getContext()" />
                                </TableCell>
                            </TableRow>
                            <TableRow v-if="row.getIsExpanded()">
                                <TableCell :colspan="row.getAllCells().length">
                                    {{ JSON.stringify(row.original) }}
                                </TableCell>
                            </TableRow>
                        </template>
                    </template>

                    <TableRow v-else>
                        <TableCell
                            :colspan="columns.length"
                            class="h-24 text-center"
                        >
                            No results.
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>

        <div class="flex items-center justify-end space-x-2 py-4">
<!--            <div class="flex-1 text-sm text-muted-foreground">-->
<!--                {{ table.getFilteredSelectedRowModel().rows.length }} of-->
<!--                {{ table.getFilteredRowModel().rows.length }} row(s) selected.-->
<!--            </div>-->
            <div class="space-x-2">
                <Button
                    variant="outline"
                    size="sm"
                    :disabled="!table.getCanPreviousPage()"
                    @click="table.previousPage()"
                >
                    Previous
                </Button>
                <Button
                    variant="outline"
                    size="sm"
                    :disabled="!table.getCanNextPage()"
                    @click="table.nextPage()"
                >
                    Next
                </Button>
            </div>
        </div>
        <TableFilters @submit="applyFilters" :show="showTableFilters" @hide="showTableFilters = false"/>
    </div>
</template>
<script setup lang="ts">
import type { ColumnDef, ColumnFiltersState, ExpandedState, SortingState, VisibilityState, } from '@tanstack/vue-table'
import {
    FlexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useVueTable,
} from '@tanstack/vue-table'
import { ArrowUpDown, FilterIcon, Grid2X2Icon, SearchIcon } from 'lucide-vue-next'

import { h, ref, watch } from 'vue'
import { Button } from '@/Components/ui/button'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow, } from '@/Components/ui/table'
import PublisherTypeCell from "@/pages/Rooms/components/publisher-type-cell.vue";
import { Link, useForm, usePage } from "@inertiajs/vue3";
import AccountCell from "@/pages/Rooms/components/account-cell.vue";
import MembersCell from "@/pages/Rooms/components/members-cell.vue";
import PlanStatusCell from "@/pages/Rooms/components/plan-status-cell.vue";
import { displayErrorMessagesAsToast } from '@/utils/errorUtils';

import { format } from '@formkit/tempo'
import ActionsCell from "@/pages/Rooms/components/actions-cell.vue";
import { InputWithIcon } from "@/Components/ui/input";
import { valueUpdater } from "@/utils/valueUpdater";
import CreateButton from "@/Components/buttons/CreateButton.vue";
import TableFilters from "@/pages/Rooms/components/table-filters.vue";
import { router } from '@inertiajs/vue3';
import axios from "axios";
import { useToast } from "primevue/usetoast";
const toast = useToast()
const page = usePage();
const rooms = ref(page.props.rooms);
const showTableFilters = ref(false);
const data = rooms.value

async function applyFilters(data) {
    const response = await axios.get('/rooms/json', {
        params: data
    })

    rooms.value = response.data.rooms;
}

const form = useForm({});
function onCreateRoom() {
    form
        .transform((data) => {
            return {
                name: 'Untitled',
                template_type: 'blank'
            }
        })
        .post('/rooms', {
            onSuccess: (data) => {
                const id = data.props.flash.room.ulid
                const pageId = data.props.flash.room?.pages?.[0]?.ulid;

                if (pageId) {
                    router.visit(`/room-detail/${id}/${pageId}`);
                }

                if (form.room_id) {
                    router.visit('/rooms');
                    setTimeout(() => {
                        toast({ title: '', description: 'The room duplication process may take a few minutes. Please refresh the page.' });
                    }, 1000)
                }
            },
            onError: (err) => {
                displayErrorMessagesAsToast(err, toast)
            },
        });
}

const columns: ColumnDef<any>[] = [
    {
        accessorKey: 'share_type',
        header: 'ACCESS',
        cell: ({ row, cell }) => {
           return h(PublisherTypeCell, {
                value: row.original.share_type
           })
        },
    },
    {
        accessorKey: 'name',
        header: ({ column }) => {
            return h(Button, {
                variant: 'ghost',
                class: 'p-0 hover:bg-gray-50',
                onClick: () => column.toggleSorting(column.getIsSorted() === 'asc'),
            }, () => ['NAME', h(ArrowUpDown, { class: 'ml-2 h-4 w-4' })])
        },
    },
    {
        accessorKey: 'account',
        header: ({ column }) => {
            return h(Button, {
                variant: 'ghost',
                class: 'p-0 hover:bg-gray-50',
                onClick: () => column.toggleSorting(column.getIsSorted() === 'asc'),
            }, () => ['ACCOUNT', h(ArrowUpDown, { class: 'ml-2 h-4 w-4' })])
        },
        cell: ({ row, cell }) => {
            return h(AccountCell, {
                value: row.original.account
            })
        },
    },
    {
        accessorKey: 'members',
        header: 'MEMBERS',
        cell: ({ row, cell }) => {
            return h(MembersCell, {
                value: row.original.members
            })
        },
    },
    {
        accessorKey: 'plans',
        header: 'PLAN STATUS',
        cell: ({ row, cell }) => {
            return h(PlanStatusCell, {
                value: row.original
            })
        },
    },
    {
        accessorKey: 'views',
        header: 'VIEWS',
        cell: ({ row, cell }) => {
            return row.original.views.length;
        },
    },
    {
        accessorKey: 'views',
        header: 'LAST CLIENT VIEW',
        cell: ({ row, cell }) => {
            return row.original.views?.[0]?.email || '';
        },
    },
    {
        accessorKey: 'modified',
        header: 'MODIFIED',
        cell: ({ row, cell }) => {
            return format(row.original.updated_at, {date: 'long', time: 'short'});
        },
    },
    {
        accessorKey: 'actions',
        header: '',
        cell: ({ row, cell }) => {
            return h(ActionsCell, {
                value: row.original,
                rooms: rooms,
                onDelete: (newData) => {
                    rooms.value = newData;
                }
            })
        },
    },
]

const sorting = ref<SortingState>([])
const columnFilters = ref<ColumnFiltersState>([])
const columnVisibility = ref<VisibilityState>({})
const rowSelection = ref({})
const expanded = ref<ExpandedState>({})

const table = useVueTable({
    get data() {
        return rooms.value
    },
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: updaterOrValue => {
        console.log(sorting);
        return valueUpdater(updaterOrValue, sorting);
    },
    onColumnFiltersChange: updaterOrValue => valueUpdater(updaterOrValue, columnFilters),
    onColumnVisibilityChange: updaterOrValue => valueUpdater(updaterOrValue, columnVisibility),
    onRowSelectionChange: updaterOrValue => valueUpdater(updaterOrValue, rowSelection),
    onExpandedChange: updaterOrValue => valueUpdater(updaterOrValue, expanded),
    state: {
        get sorting() { return sorting.value },
        get columnFilters() { return columnFilters.value },
        get columnVisibility() { return columnVisibility.value },
        get rowSelection() { return rowSelection.value },
        get expanded() { return expanded.value },
        get data() {
            return data.value
        }
    },
})

function onClickTableRow(row) {
    router.visit('/room-detail/' + row.original.ulid)
}

watch(data.value, (newData) => {
    console.log('table', table)
    table.data = newData;
});
</script>
