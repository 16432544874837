import 'primeicons/primeicons.css'
// import 'primevue/resources/themes/aura-light-blue/theme.css'
import InputText from "primevue/inputtext";
import ConfirmationService from 'primevue/confirmationservice';
import SplitButton from 'primevue/splitbutton';
import ConfirmDialog from "primevue/confirmdialog";
import Sidebar from "primevue/sidebar";
import Checkbox from "primevue/checkbox";
import Button from "primevue/button";
import Badge from "primevue/badge";
import PrimeVue from "primevue/config";
import Lara from "./presets/lara/index.js";
import Toast from 'primevue/toast';
import ToastService from "primevue/toastservice";
import Dialog from 'primevue/dialog';
import Tooltip from 'primevue/tooltip';
import Chips from 'primevue/chips';
import ProgressBar from 'primevue/progressbar';

export default {
    install(Vue) {
        Vue.use(PrimeVue, {
            pt: Lara
        })
        Vue.use(ToastService);
        Vue.use(ConfirmationService);
        Vue.component(Chips.name, Chips);
        Vue.component(Sidebar.name, Sidebar);
        Vue.component(InputText.name, InputText);
        Vue.component(Button.name, Button);
        Vue.component(Toast.name, Toast);
        Vue.component(SplitButton.name, SplitButton);
        Vue.component(Badge.name, Badge);
        Vue.component(ConfirmDialog.name, ConfirmDialog);
        Vue.component(ProgressBar.name, ProgressBar);
        Vue.component(Dialog.name, Dialog);
        Vue.directive('tooltip', Tooltip)
    }
}
