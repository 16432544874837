<template>
    <div class="flex justify-end group-hover:opacity-100">
        <Button variant="ghost" size="small" aria-label="Edit" @click.prevent="(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onCopy(value)
                        }">
            <Icon name="Copy"></Icon>
        </Button>
<!--        <Link :href="'/room-detail/' + value.ulid" class="text-blue-600 group-hover:opacity-100">-->

<!--            <Button variant="ghost" size="small" aria-label="Edit">-->
<!--                <Icon name="Eye"></Icon>-->
<!--            </Button>-->
<!--        </Link>-->
        <Button variant="ghost" size="small" aria-label="Edit" @click.prevent="(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            router.visit('/rooms/' + value.ulid)
                        }">
            <Icon name="Pencil"></Icon>
        </Button>

        <DeleteRow v-if="canModifyRoom(value)" @onDelete="() => onDelete(data)"/>
    </div>
</template>
<script setup >
import Icon from "@/tiptap/components/ui/Icon.vue";
import { Link, useForm } from '@inertiajs/vue3';
import Button from "@/tiptap/components/ui/button/Button.vue";
import { defineProps } from "vue";
import { useAuth } from '@/hooks/useAuth.js';
import DeleteRow from '@/Components/DeleteRow.vue';
import { displayErrorMessagesAsToast } from '../../../utils/errorUtils.js';
import { router } from "@inertiajs/vue3";
import { useToast } from 'primevue/usetoast';
const {canModifyRoom} = useAuth();

const toast = useToast();
const props = defineProps({
    value: {
        type: Object
    },
    rooms: {
        type: Array
    },
    onDelete: {
        type: Function
    },
})

function onCopy(room) {
    axios.post(`/rooms/${room.ulid}/duplicate`)
        .then((response) => {
            toast.add({severity: 'success', summary: '', detail: 'The room duplication process may take a few minutes. Please refresh the page.', life: 3000});
        })
        .catch((error) => {
            displayErrorMessagesAsToast(error, toast)
            console.error(error);
        });
}
const form = useForm({});

function onDelete(data) {
    form.delete('/rooms/' + props.value.ulid, {
        preserveScroll: true,
        onError: (err) => {
            displayErrorMessagesAsToast(err, toast)
        },
        onSuccess: () => {
            const newRooms = props.rooms.value.filter(room => room.id !== props.value.id);

            props.onDelete(newRooms)
        }
    });
}

</script>
