<template>
    <AuthenticatedLayout>
        <template #title>Create room</template>
        <RoomForm @submit="submit" :data="[]" />
    </AuthenticatedLayout>
</template>
<script setup>
import AuthenticatedLayout from '../../Layouts/AuthenticatedLayout.vue';
import { router, useForm, usePage } from '@inertiajs/vue3';
import { displayErrorMessagesAsToast } from '../../utils/errorUtils.js';
import RoomForm from './RoomForm.vue';
import { formatDateToISO } from '../../utils/formatDate.js';
import { useToast } from 'primevue/usetoast';

const toast = useToast();
const form = useForm({});

const submit = (submittedForm) => {
    form
        .transform((data) => {
            data = {
                ...submittedForm
            }
            data.users = data.users.map(user => user.id);
            data.start_date = data.start_date ? formatDateToISO(data.start_date[0]) : null;
            data.end_date = data.end_date ? formatDateToISO(data.end_date[0]) : null;
console.log({data});
            return data;
        })
        .post('/rooms', {
            onSuccess: (data) => {
                const id = data.props.flash.room.ulid
                const pageId = data.props.flash.room?.pages?.[0]?.ulid;

                if (pageId) {
                    router.visit(`/room-detail/${id}/${pageId}`);
                }

                if (form.room_id) {
                    router.visit('/rooms');
                    setTimeout(() => {
                        toast({ title: '', description: 'The room duplication process may take a few minutes. Please refresh the page.' });
                    }, 1000)
                }
            },
            onError: (err) => {
                displayErrorMessagesAsToast(err, toast)
            },
        });
};
</script>
