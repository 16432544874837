<template>
    <div class="bg-white pb-10">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <div class="mx-auto max-w-4xl text-center">
                <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Appsumo Plan
                </p>
            </div>
            <p class="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600"></p>
            <Tabs :defaultValue="frequency.value" class="w-full justify-center flex mt-16"
                  @update:modelValue="e => frequency = frequencies.find(f => f.value === e)"
            >
                <TabsList>
                    <TabsTrigger v-for="option in frequencies" :value="option.value" >{{ option.label }}</TabsTrigger>
                </TabsList>
            </Tabs>
            <div class="isolate mx-auto mt-10 max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-2 flex justify-center">
                <div v-for="tier in tiers" :key="tier.id"
                     class="w-[500px]"
                     :class="[
                         tier.featured ? 'bg-gray-900 ring-gray-900' : 'ring-gray-200', 'rounded-3xl p-8 ring-1 xl:p-10',
                         isActive(
                             ['free', 'enterprise'].includes(tier.id) ? 'free' : frequency.value.value === 'monthly' ? 'monthly_pro' : 'yearly_pro'
                         ) ? 'ring-2 ring-indigo-600' : 'ring-1 ring-gray-200', 'rounded-3xl p-8 xl:p-10'
                     ]"

                >
                    <h3 :id="tier.id"
                        :class="[tier.featured ? 'text-white' : 'text-gray-900', 'text-lg font-semibold leading-8']">
                        {{ tier.name }}</h3>
                    <p :class="[tier.featured ? 'text-gray-300' : 'text-gray-600', 'mt-4 text-sm leading-6']">
                        {{ tier.description }}</p>

                    <a @click="(e) => {e.preventDefault();onPlanSelect(tier)}" :aria-describedby="tier.id"
                       class="cursor-pointer"
                       :class="[tier.featured ? 'bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white' : 'bg-primary-600 text-white shadow-sm hover:bg-primary-500 focus-visible:outline-primary-600', 'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2']">{{
                            tier.cta()
                        }}</a>
                    <ul role="list"
                        :class="[tier.featured ? 'text-gray-300' : 'text-gray-600', 'mt-8 space-y-3 text-sm leading-6 xl:mt-10']">
                        <li v-for="feature in tier.features" :key="feature" class="flex gap-x-3">
                            <CircleCheckBig
                                :class="[tier.featured ? 'text-white' : 'text-green-600', 'h-6 w-5 flex-none']"
                                aria-hidden="true"/>
                            {{ feature }}
                        </li>
                    </ul>
                </div>
            </div>

            <Dialog
                v-if="showDialog"
                v-model:visible="showDialog"
                modal
                :header="`Add additional code`"
                :style="{ width: '36rem' }"
                :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
                :dismissable-mask="true"
            >
                <BaseFormCard @submit="addMoreCode" :form="addCodeForm" style="height: 180px;" class="w-full">
                    <div class="flex align-items-center gap-3 mb-3 w-full">
                        <FormField v-slot="{ componentField }" name="code">
                            <FormItem v-auto-animate class="col-span-6 w-full">
                                <FormLabel>Code</FormLabel>
                                <FormControl>
                                    <Input v-bind="componentField" autofocus class="w-full border border-gray-200" placeholder="Insert your code"/>
                                </FormControl>
                                <FormMessage/>
                            </FormItem>
                        </FormField>
                    </div>
                    <template #footer>
                        <div class="flex justify-center gap-2">
                            <Button type="button" size="small" label="Cancel"
                                    variant="secondary"
                                    class="min-w-24"
                                    @click="showDialog = false">
                                Cancel
                            </Button>
                            <Button type="submit" size="small" class="min-w-24" label="Save"
                                    :loading="addCodeForm.processing"
                            >
                                Save
                            </Button>
                        </div>
                    </template>
                </BaseFormCard>
            </Dialog>
        </div>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { router, usePage } from '@inertiajs/vue3';
import { CircleCheckBig } from 'lucide-vue-next'
import { Tabs, TabsList, TabsTrigger } from '@/Components/ui/tabs'
import Button from '@/tiptap/components/ui/button/Button.vue';
import { Input } from '@/Components/ui/input'
import { FormControl, FormField, FormItem, FormMessage, FormLabel } from '@/Components/ui/form'
import BaseFormCard from '@/Components/forms/BaseFormCard.vue';
import useValidatedForm from "@/hooks/useValidatedFormNew";
import { displayErrorMessagesAsToast } from '@/utils/errorUtils';
import { useToast } from 'primevue/usetoast';
const toast = useToast();

const page = usePage();
const plans = page.props.plans;
const subscription = page.props.subscription;
const subscription_target = ref(page.props.subscription_target);
const quantity = subscription.quantity;
const showDialog = ref(false)
const frequencies = [
    { value: 'lifetime', label: 'Lifetime', priceSuffix: `/user` },
];

const addCodeForm = useValidatedForm({
    code: ''
}, {})

const frequency = ref(
    subscription?.plan?.identifier === 'yearly_pro'
        ? frequencies[1]
        : frequencies[0]
)

const tiers = computed(() => [
    {
        name: 'Appsumo Lifetime',
        id: 'monthly_pro',
        href: '#',
        description: '',
        features: [
            `${subscription_target.value?.total_rooms} Rooms`,
            `${subscription_target.value?.total_storage}GB Storage`,
            `${subscription_target.value?.total_users} User${subscription_target.value?.total_users > 1 ? 's' : ''}`,
        ],
        featured: false,
        cta: () => {
            return 'Upgrade Plan'
        },
    }
])

async function onPlanSelect(tier) {
    showDialog.value = true;
}

function getPlanIdentifier() {
    return frequency.value.value === 'monthly' ? 'monthly_pro' : 'yearly_pro';
}

function isActive(tier) {
    const t = tier?.id || tier;

    if (!subscription?.plan && t === 'free') {
        return true;
    }

    if (t === 'free' && subscription?.plan?.identifier === 'free') {
        return true;
    }

    const selectedTier = ['monthly_pro', 'yearly_pro'].includes(tier) ? getPlanIdentifier() : tier.id;

    return selectedTier === subscription?.plan?.identifier;
}

function addMoreCode() {
    addCodeForm.post('/add-app-sumo-code', {
        onSuccess: (data) => {
            toast.add({
                severity: 'success',
                summary: 'Plan upgraded successfully',
                detail: 'Your plan has been successfully upgraded.',
                life: 3000,
            })

            subscription_target.value = data.props.subscription_target;
            addCodeForm.reset()
            router.reload();
            showDialog.value = false
        },
        onError: (err) => {
            displayErrorMessagesAsToast(err, toast)
        },
    });
}

</script>
