<template>
    <AuthenticatedLayout>
        <template #title>Dashboard</template>
        <Dialog v-model:visible="dialog" modal :header="`Interested in Dashboards?`"
                :pt="{
        root: 'border-none',
        mask: {
            style: 'backdrop-filter: blur(2px)'
        }
    }"
        >
            If you're interested in dashboards, please contact us at
            <a href="mailto:info@roombriks.com" class="text-blue-700">info@roombriks.com</a>

            <div class="dialog-actions">
                <div class="flex gap-2 justify-end mt-10">
                    <Button
                        size="small"
                        type="submit"
                        @click="goBack"
                    >
                        <span>Go back</span>
                    </Button>
                </div>
            </div>
        </Dialog>

        <div class="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
            <DataStatsOne/>
        </div>

        <div class="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
            <RoomValueOverTimeChart/>
            <AvgTimeSpentChart/>
            <TotalRoomViewsOverTimeChart/>
            <CompletedRoomsChart/>
            <ColdRooms/>
            <ActiveRooms/>
            <LostRooms/>
        </div>
    </AuthenticatedLayout>
</template>
<script setup>
import { router } from '@inertiajs/vue3'
import AuthenticatedLayout from '../../Layouts/AuthenticatedLayout.vue';
import DataStatsOne from '@/Components/DataStats/DataStatsOne.vue';
import RoomValueOverTimeChart from '@/Components/charts/RoomValueOverTimeChart.vue';
import AvgTimeSpentChart from '@/Components/charts/AvgTimeSpentChart.vue';
import TotalRoomViewsOverTimeChart from '@/Components/charts/TotalRoomViewsOverTimeChart.vue';
import CompletedRoomsChart from '@/Components/charts/CompletedRoomsChart.vue';
import ColdRooms from '@/Components/charts/ColdRooms.vue';
import ActiveRooms from '@/Components/charts/ActiveRooms.vue';
import LostRooms from '@/Components/charts/LostRooms.vue';
import { ref } from 'vue';
import Button from '../../tiptap/components/ui/button/Button.vue';

const dialog = ref(true);

function goBack() {
    router.visit('/rooms');

    if (window.history.length && window.history.length > 1) {
        return window.history.back();
    }

    return router.visit('/rooms');
}
</script>
