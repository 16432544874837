<template>
    <Button variant="ghost" class="flex items-center w-full !p-0 group">

        <Link class="py-2 px-3 flex-1 text-left text-gray-950 font-normal truncate " :href="fullLink">{{props.page.name}}</Link>
        <PopoverRoot  v-if="editable">
            <PopoverTrigger asChild>
                <ToolbarButton class="opacity-0 group-hover:opacity-100" buttonSize="icon">
                    <Icon name="EllipsisVertical"/>
                </ToolbarButton>
            </PopoverTrigger>
            <PopoverContent side="bottom" align="start" :sideOffset="8" style="z-index: 9999;">
                <Surface class="p-2 flex flex-col ">
                    <PopoverClose>
                        <DropdownButton @click="onEdit">
                            <Icon name="Pencil"/>
                            Edit
                        </DropdownButton>
                    </PopoverClose>
                    <ToolbarDivider horizontal/>
                    <PopoverClose>
                        <DropdownButton
                            @click="onDelete"
                            class="text-red-500 bg-red-500 dark:text-red-500 hover:bg-red-500 dark:hover:text-red-500 dark:hover:bg-red-500 bg-opacity-10 hover:bg-opacity-20 dark:hover:bg-opacity-20"
                        >
                            <Icon name="Trash2"/>
                            Delete
                        </DropdownButton>
                    </PopoverClose>
                </Surface>
            </PopoverContent>
        </PopoverRoot>
    </Button>
</template>
<script setup>
import { computed, ref } from 'vue';
import { useForm, usePage, Link } from '@inertiajs/vue3';
import { displayErrorMessagesAsToast } from '../../../utils/errorUtils.js';
import { useRoomPage } from '../hooks/useRoomPage.js';
import { useConfirmationModal } from '../../../hooks/useConfirmationModal.js';
import Button from "../../../tiptap/components/ui/button/Button.vue";
import {PopoverClose, PopoverContent, PopoverRoot, PopoverTrigger} from "radix-vue";
import Surface from "../../../tiptap/components/ui/Surface.vue";
import DropdownButton from "../../../tiptap/components/ui/dropdown/DropdownButton.vue";
import Icon from "../../../tiptap/components/ui/Icon.vue";
import ToolbarDivider from "../../../tiptap/components/ui/toolbar/ToolbarDivider.vue";
import ToolbarButton from "@/tiptap/components/ui/toolbar/ToolbarButton.vue";
import { useToast } from 'primevue/usetoast';
const toast = useToast();
const props = defineProps({
    page: Object,
});

const emit = defineEmits(['onDelete', 'onEdit']);


const usepage = usePage();

const {editable, external} = useRoomPage();
const menu = ref();

const deleteForm = useForm({
    id: props.page.id,
});

const link = computed(() => {
    if (external.value) {
        return 'room'
    }

    return editable.value ? 'room-detail' :'room-view'
});

const fullLink = computed(() => {
    const room = usepage.props.room;

    if (external.value) {
        return `/a/${room.account?.slug || "n"}/${room.ulid}/${props.page.ulid}`
    }

    return `/${link.value}/${room.ulid}/${props.page.ulid}`;
})

const confirm = useConfirmationModal();

function onEdit() {
    emit('onEdit')
}

function onDelete() {
    confirm({
        accept: () => {
            deleteForm.delete(`/page/${props.page.ulid}`, {
                onSuccess: () => {
                    emit('onDelete');
                },
                onError: (err) => {
                    displayErrorMessagesAsToast(err, toast)
                },
            });
        },
    })
}

</script>
