<template>
    <div class="flex">
        <div
            v-tooltip.right="getShareOption(value).name"
            class="flex items-center justify-center border rounded text-xs py-0.5 text-center uppercase px-2"
            :class="getShareOption(value).iconColor"
        >
            {{ getShareOption(value).name }}
        </div>
    </div>
</template>
<script setup>
import { ref } from 'vue';

const props = defineProps({
    value: {
        type: String
    }
})

const shareOptions = ref([
    {
        value: 'admin',
        name: 'Admin',
        description: 'Email gate, no verification',
        icon: 'pi pi-link',
        iconColor: 'border-blue-400 text-blue-600 bg-blue-25'
    },
    {
        value: 'user',
        name: 'User',
        description: 'Not visible to anyone',
        icon: 'pi pi-eye-slash',
        iconColor: 'border-yellow-600 text-yellow-600 bg-yellow-50'
    },
]);

function getShareOption(value) {
    console.log('value', value);
    return shareOptions.value.find(option => option.value === value);
}
</script>
