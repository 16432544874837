<template>
    <div class="flex items-center w-full !p-0 group justify-start py-0.5 my-0.5 ml-4 cursor-pointer text-gray-500 text-base"
            @click="onItemClick"
        :class="{
          'is-active': item.isActive && !item.isScrolledOver,
          'is-scrolled-over': item.isScrolledOver,
          '!justify-start': true,
          '!py-2 !px-3': true
        }"
        :style="{
            '--level': item.level,
            'padding-left': `${(item.level - 1) * 10}px !important`,
            'border-bottom': hasBorder ? '1px solid #E5E7EB' : 'none',
        }"
    >
        <Icon name="Dot" />{{ item.textContent }}
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import Button from '@/tiptap/components/ui/button/Button.vue';
import Icon from '@/tiptap/components/ui/Icon.vue';

export default defineComponent({
    components: {Icon, Button},
    props: {
        item: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        hasBorder: {
            type: Boolean,
            default: false,
        }
    },

    emits: ['item-click'],

    methods: {
        onItemClick(event) {
            const element = document.getElementById(this.$props.item.id);
            const offset = 200;

            if (element) {
                const elementPosition = element.getBoundingClientRect().top + window.scrollY;
                const offsetPosition = elementPosition - offset;

                setTimeout(() => {
                    window.scrollTo({
                        top: offsetPosition < 0 ? 0 : offsetPosition,
                    });
                }, 0)
            }
            this.$emit('item-click', event, this.item.id)
        },
    },
})
</script>
