<template>
    <AuthLayout :class="'!py-0'">
        <h1 class="text-center">
            Finish Registration
        </h1>
        <BaseFormCard class="w-full mt-12 flex flex-col gap-2" @submit="submit" :form="form">
            <div class="flex gap-4">
                <div class="flex flex-col gap-2">

                    <FormField v-slot="{ componentField }" name="first_name">
                        <FormItem v-auto-animate>
                            <FormLabel>First name</FormLabel>
                            <FormControl>
                                <Input v-bind="componentField"/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    </FormField>
                </div>
                <div class="flex flex-col gap-2">
                    <FormField v-slot="{ componentField }" name="last_name">
                        <FormItem v-auto-animate>
                            <FormLabel>Last name</FormLabel>
                            <FormControl>
                                <Input v-bind="componentField"/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    </FormField>
                </div>
            </div>
            <div class="flex flex-col gap-4">
                <div class="flex flex-col gap-2">
                    <FormField v-slot="{ componentField }" name="password">
                        <FormItem v-auto-animate>
                            <FormLabel>Password</FormLabel>
                            <FormControl>
                                <InputWithIcon :icon="LockIcon" type="password" v-bind="componentField"/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    </FormField>
                </div>
            </div>
            <div class="flex flex-col gap-4">
                <div class="flex flex-col gap-2">
                    <FormField v-slot="{ componentField }" name="password_confirmation">
                        <FormItem v-auto-animate>
                            <FormLabel>Password confirmation</FormLabel>
                            <FormControl>
                                <InputWithIcon :icon="LockIcon" type="password" v-bind="componentField"/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    </FormField>
                </div>
            </div>
            <Divider/>
            <div class="flex flex-col gap-4">
                <span class="relative">
                    <div class="flex flex-col gap-2">
                        <FormField v-slot="{ componentField }" name="company_name">
                            <FormItem v-auto-animate>
                                <FormLabel>Company name</FormLabel>
                                <FormControl>
                                    <Input v-bind="componentField" @blur="onBlurCompanyName"/>
                                </FormControl>
                                <FormMessage/>
                            </FormItem>
                        </FormField>
                    </div>
                </span>
            </div>
            <div class="flex flex-col gap-2">
                <span class="relative">
                    <FormField v-slot="{ componentField }" name="subdomain">
                        <FormItem v-auto-animate>
                            <FormLabel>Account URL</FormLabel>
                            <FormControl>
                                <div class="flex border items-center">
                                    <InputWithIcon :icon="LinkIcon" v-bind="componentField" class="border-0"/>
                                    <div
                                            class="right-0 px-4 border-l border-gray-300 py-1.5 h-full flex items-center"
                                            :class="{
                                            'border-red-500': !!onboardingForm.errors.subdomain
                                        }"
                                        >
                                            .{{ masterDomain }}
                                    </div>
                                </div>
                                <p class="text-xs !text-gray-500 mt-2">This will be the URL for your account. For example, if you enter 'mycompany`, your account URL will be 'mycompany.{{ masterDomain }}.</p>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    </FormField>
                </span>
            </div>
            <div class="flex flex-col gap-2">
                <span class="relative">
                    <FormField v-slot="{ componentField }" name="company_website">
                        <FormItem v-auto-animate>
                            <FormLabel>Company website</FormLabel>
                            <FormControl>
                                <InputWithIcon :icon="GlobeAltIcon" v-bind="componentField"/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    </FormField>
                </span>
            </div>
            <Divider/>
            <div class="flex flex-col gap-2" v-if="code">
                <FormField v-slot="{ componentField }" name="code">
                    <FormItem v-auto-animate>
                        <FormLabel>Appsumo code</FormLabel>
                        <FormControl>
                            <Input v-bind="componentField"/>
                        </FormControl>
                        <FormMessage/>
                    </FormItem>
                </FormField>
            </div>
            <div class="flex flex-col gap-2 mt-4">
                <FormField v-slot="{ value, handleChange }" type="checkbox" name="terms">
                    <FormItem v-auto-animate class="flex items-start space-x-2 mt-6">
                        <FormControl>
                            <Checkbox :checked="value" @update:checked="handleChange"/>
                        </FormControl>
                        <div class="!mt-0 flex w-full justify-between">
                            <div class="mt-1">
                                <FormLabel class="pt-0">I agree with the <a href="https://roombriks.com/terms" target="_blank">Terms & Conditions</a></FormLabel>
                                <FormMessage/>
                            </div>
                        </div>
                    </FormItem>
                </FormField>
            </div>

            <template #footer>
                <div class="mt-10">
                    <Button class="w-full" size="small" type="submit"  :loading="onboardingForm.processing">
                        Register
                    </Button>
                </div>
            </template>
        </BaseFormCard>
    </AuthLayout>
</template>
<script setup>
import * as yup from 'yup';
import { usePage } from '@inertiajs/vue3';
import AuthLayout from '@/Layouts/AuthLayout.vue';
import { GlobeAltIcon, LinkIcon } from '@heroicons/vue/24/outline';
import { Input, InputWithIcon } from '@/Components/ui/input'
import Divider from 'primevue/divider';
import { displayErrorMessagesAsToast } from '../../utils/errorUtils.js';
import useValidatedForm from '../../hooks/useValidatedFormNew';
import { useToast } from 'primevue/usetoast';
import BaseFormCard from '@/Components/forms/BaseFormCard.vue';
import { FormControl, FormField, FormItem, FormLabel, FormMessage, } from '@/Components/ui/form'
import { Checkbox } from '@/Components/ui/checkbox'
import Button from '@/tiptap/components/ui/button/Button.vue';
import { LockIcon } from 'lucide-vue-next';

const toast = useToast();
const params = new URLSearchParams(window.location.search);
const page = usePage();
const masterDomain = page.props.masterDomain;

const schema = yup.object().shape({
    subdomain: yup.string().required('Please enter your account url.'),
    first_name: yup.string().required('Please enter your first name.'),
    last_name: yup.string().required('Please enter your last name.'),
    company_website: yup.string().notRequired(),
    password: yup.string()
        .required()
        .min(8, 'Password must be at least 8 characters long')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[0-9]/, 'Password must contain at least one number')
        .matches(/[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`]/, 'Password must contain at least one symbol')
        .test('no-spaces', 'Password must not contain spaces', value => !/\s/.test(value)),
    password_confirmation: yup.string()
        .required('Please retype your password.')
        .oneOf([yup.ref('password')], 'Your passwords do not match.'),
    company_avatar: yup.string().required('Please upload your company avatar.').notRequired(),
    company_name: yup.string().required('Please enter your company name.'),
    user_avatar: yup.string().required('Please upload your user avatar.').notRequired(),
    terms: yup.boolean().oneOf([true], 'Please accept the terms and conditions.'),
});

const code = params.get('code');

const onboardingForm = useValidatedForm({
    subdomain: '',
    first_name: '',
    last_name: '',
    password: '',
    password_confirmation: '',
    company_avatar: '',
    company_name: '',
    company_website: '',
    user_avatar: '',
    terms: false,
    code: params.get('code'),
}, schema);

const submit = () => {
    onboardingForm
        .transform((data) => {
            if (! code) {
                delete data.code;

            } else {
                data['is_appsumo'] = true;
            }

            return {
                ...data,
                email: page.props.email,
                hash: page.props.hash,
            }
        })
        .post('/onboarding', {
            onSuccess: (data) => {
                window.location.href = data.props.flash.redirectUrl + `?showWelcomeModal=true&showAppSumoModal=${data.props.flash.show_appsumo_dialog}`;
            },
            onError: (err) => {
                displayErrorMessagesAsToast(err, toast)
            },
        });
};

function onBlurCompanyName() {
    onboardingForm.vee.setFieldValue('subdomain', onboardingForm.company_name.toLowerCase().replace(/[^a-z0-9]/g, ''))
}
</script>
