<template>
    <div class="flex self-start flex-row-reverse gap-2 group">
        <div class="flex flex-col">
            <p class="text-xs text-gray-500 mt-0.5 text-start mb-1">{{ creator }}</p>
            <div class="flex items-center self-start rounded-xl rounded-tl text-gray-600 break-words">
                <p>{{ props.comment.comment }}</p>
            </div>
            <p class="text-xs text-gray-500 mt-0.5 opacity-0 group-hover:opacity-100">{{ createdAt }}</p>
        </div>
        <BaseAvatar
            :src="props.comment.creator?.avatar"
            :name="creator"
            class="w-8 h-8 rounded-full border mt-1"
        />
    </div>
</template>
<script setup>
import BaseAvatar from '../forms/BaseAvatar.vue';
import { format } from 'date-fns';
const props = defineProps({
    comment: {
        type: Object,
        required: true,
    },
});

const createdAt = format(new Date(props.comment.created_at), 'yyyy/MM/dd hh:mm:ss a');
const creator = props.comment.creator?.name || props.comment.email;
</script>
