<template>
    <div class="flex">
        <div
            v-tooltip.right="getShareOption(value).name"
            class="flex items-center justify-center border rounded text-xs py-0.5 text-center uppercase px-2"
            :class="getShareOption(value).iconColor"
        >
            {{ getShareOption(value).name }}
        </div>
    </div>
</template>
<script setup>
import { ref } from 'vue';
import { GlobeLock } from 'lucide-vue-next';

const props = defineProps({
    value: {
        type: String
    }
})

const shareOptions = ref([
    {
        value: true,
        name: 'Yes',
        icon: 'pi pi-link',
        iconColor: 'border-green-400 text-green-600 bg-green-25'
    },
    {
        value: false,
        name: 'No',
        icon: 'pi pi-eye-slash',
        iconColor: 'border-red-400 text-red-600 bg-red-25'
    },
]);

function getShareOption(value) {
    console.log('value', value);
    return shareOptions.value.find(option => option.value === value);
}
</script>
