<template>
    <AuthenticatedLayout>
        <template #title>Content</template>
        <LibraryTable @showUpload="showUploadDialog = true" @onRowClick="onRowClick"/>
        <FilePreviewDialog
            v-model:visible="showPreviewDialog"
            :media="selectedRow"
        />
        <DeleteRowDialog/>
        <BaseAvatarPicker
            v-model:visible="showUploadDialog"
            ref="avatarPicker"
            :upload-function="createImage"
            :append-to-body="false"
            :disabled-library="true"
        />
    </AuthenticatedLayout>
</template>
<script setup>
import AuthenticatedLayout from '../../Layouts/AuthenticatedLayout.vue';
import { router, usePage } from '@inertiajs/vue3';
import DeleteRowDialog from '@/Components/DeleteRowDialog.vue';
import { ref } from 'vue';
import BaseAvatarPicker from '@/Components/forms/BaseAvatarPicker.vue';
import { useBlockImage } from '../../hooks/useBlockImage.js';
import FilePreviewDialog from '@/Components/FilePreviewDialog.vue';
import LibraryTable from '@/pages/Content/LibraryTable.vue';

const page = usePage();
const medias = ref(page.props.medias);
const showUploadDialog = ref(false);
const {uploadImage} = useBlockImage();

const showPreviewDialog = ref(false)
const selectedRow = ref()

function onRowClick(row) {
    selectedRow.value = row
    showPreviewDialog.value = true
}


async function createImage(file) {
    return new Promise((resolve, reject) => {
        uploadImage(file, usePage().props.room, async (url, response) => {
            medias.value = {
                ...medias.value,
                ...response.data
            };
            showUploadDialog.value = false;
            resolve();
            router.reload();
        }, () => {
            reject();
        })
    });

}
</script>
