<template>
    <AuthenticatedLayout>
        <template #title>Company profile</template>
        <BaseFormCard @submit="submit" :loading="form.processing" save-text="Update" :form="form">
            <FormCardSection title="Profile Information" container-class="-mt-4">
                <FormField v-slot="{ componentField }" name="name">
                    <FormItem v-auto-animate class="col-span-6">
                        <FormLabel>Company Name</FormLabel>
                        <FormControl>
                            <Input v-bind="componentField"/>
                        </FormControl>
                        <FormMessage/>
                    </FormItem>
                </FormField>
                <FormField name="avatar">
                    <FormItem v-auto-animate class="col-span-6">
                        <FormLabel>Avatar</FormLabel>
                        <FormControl>
                            <AvatarUpload
                                :can-crop="false"
                                :name="tenant.name"
                                v-model="form.avatar"
                                @update:modelValue="(e) => {
                                    form.vee.setFieldValue('avatar', e);
                                }"
                            />
                        </FormControl>
                        <FormMessage/>
                    </FormItem>
                </FormField>

                <FormField v-slot="{ componentField }" name="openai_type">
                    <FormItem v-auto-animate class="col-span-6">
                        <FormLabel>Default AI Model</FormLabel>
                        <FormControl>
                            <Select v-bind="componentField">
                                <FormControl>
                                    <SelectTrigger>
                                        <SelectValue placeholder="Select the AI model you want to use"/>
                                    </SelectTrigger>
                                </FormControl>
                                <SelectContent>
                                    <SelectGroup>
                                        <SelectItem value="gemeni">
                                            Gemeni
                                        </SelectItem>
                                        <SelectItem value="chatgpt">
                                            ChatGPT
                                        </SelectItem>
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </FormControl>
                        <FormMessage/>
                    </FormItem>
                </FormField>

                <FormField v-slot="{ componentField }" name="openai_api_key">
                    <FormItem v-auto-animate class="col-span-6">
                        <FormLabel>ChatGPT API Key</FormLabel>
                        <FormControl>
                            <Input v-bind="componentField" @input="handleOpenAIApiKeyChange"/>
                        </FormControl>
                        <FormMessage/>
                    </FormItem>
                </FormField>
                <FormField v-slot="{ componentField }" name="gemeni_api_key">
                    <FormItem v-auto-animate class="col-span-6">
                        <FormLabel>Gemeni API Key</FormLabel>
                        <FormControl>
                            <Input v-bind="componentField" @input="handleGemeniAIApiKeyChange"/>
                        </FormControl>
                        <FormMessage/>
                    </FormItem>
                </FormField>
            </FormCardSection>
        </BaseFormCard>
    </AuthenticatedLayout>
</template>
<script setup>
import * as yup from "yup";
import AuthenticatedLayout from '../../Layouts/AuthenticatedLayout.vue';
import FormCardSection from '@/Components/forms/FormCardSection.vue';
import BaseFormCard from '@/Components/forms/BaseFormCard.vue';
import { router, usePage } from '@inertiajs/vue3';
import { displayErrorMessagesAsToast } from '../../utils/errorUtils.js';
import AvatarUpload from '@/Components/forms/AvatarUpload.vue';
import useValidatedForm from '../../hooks/useValidatedFormNew';
import { ref } from 'vue';
import { FormControl, FormField, FormItem, FormLabel, FormMessage, } from '@/Components/ui/form'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/Components/ui/select'
import { Input } from "@/Components/ui/input";
import { useToast } from 'primevue/usetoast';
const toast = useToast();

const tenant = usePage().props.auth.tenant;
const openAiapiKey = ref(tenant.openai_api_key);
const maskedOpenAiApiKey = ref(maskApiKey(openAiapiKey.value));
const gemeniapiKey = ref(tenant.gemeni_api_key);
const maskedGemeniAiApiKey = ref(maskApiKey(gemeniapiKey.value));

const schema = yup.object().shape({
    name: yup.string().required('Please enter your company name.'),
    avatar: yup.mixed().notRequired(),
    openai_api_key: yup.string().notRequired(),
});

const form = useValidatedForm({
    name: tenant.name,
    avatar: tenant.avatar,
    openai_api_key: maskApiKey(tenant.openai_api_key),
    gemeni_api_key: maskApiKey(tenant.gemeni_api_key),
    openai_type: tenant.openai_type,
}, schema, ['avatar']);

function maskApiKey(apiKey) {
    if (!apiKey) return '';
    const visibleLength = 4; // Number of visible characters at the end
    const maskedPart = apiKey.slice(0, -visibleLength).replace(/./g, '*');
    const visiblePart = apiKey.slice(-visibleLength);
    return `${maskedPart}${visiblePart}`;
}

function handleOpenAIApiKeyChange(event) {
    openAiapiKey.value = event.target.value;
    maskedOpenAiApiKey.value = maskApiKey(openAiapiKey.value);
    form.openai_api_key = openAiapiKey.value;
}

function handleGemeniAIApiKeyChange(event) {
    gemeniapiKey.value = event.target.value;
    maskedGemeniAiApiKey.value = maskApiKey(gemeniapiKey.value);
    form.gemeni_api_key = gemeniapiKey.value;
}

function submit() {
    form
        .transform(data => {
            if (!(data.avatar instanceof File)) {
                delete data['company_avatar'];
            }

            return data;
        })
        .post('/company', {
            forceFormData: true,
            onSuccess: () => {
                router.reload();
            },
            onError: (err) => {
                displayErrorMessagesAsToast(err, toast)
            },
        })
}

</script>
