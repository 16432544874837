<template>
    <TransitionRoot as="template" :show="sidebarOpen">
        <Dialog as="div" class="relative z-50 lg:hidden" @close="sidebarOpen = false">
            <TransitionChild as="template" enter="transition-opacity ease-linear duration-300"
                             enter-from="opacity-0" enter-to="opacity-100"
                             leave="transition-opacity ease-linear duration-300" leave-from="opacity-100"
                             leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-900/80"/>
            </TransitionChild>

            <div class="fixed inset-0 flex">
                <TransitionChild as="template" enter="transition ease-in-out duration-300 transform"
                                 enter-from="-translate-x-full" enter-to="translate-x-0"
                                 leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0"
                                 leave-to="-translate-x-full">
                    <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">

                        <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0"
                                         enter-to="opacity-100" leave="ease-in-out duration-300"
                                         leave-from="opacity-100" leave-to="opacity-0">
                            <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                                <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                                    <span class="sr-only">Close sidebar</span>
                                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true"/>
                                </button>
                            </div>
                        </TransitionChild>
                        <!-- Sidebar component, swap this element with another sidebar if you like -->
                        <div
                            class="flex grow flex-col gap-y-5 overflow-y-auto sidebar-background px-6 pb-2">
                            <Link href="/rooms" class="flex h-16 shrink-0 items-center">
                                <img class="h-6 w-auto" src="/images/logo.png" alt="Your Company"/>
                            </Link>
                            <nav class="flex flex-1 flex-col">

                                <ul role="list" class="flex flex-col gap-y-7">
                                    <li>
                                        <ul role="list" class="-mx-2 space-y-1 text-gray-700">
                                            <SidebarItem
                                                v-for="item in navigation"
                                                :key="item.name"
                                                :item="item"
                                                :is-sidebar-visible="isSidebarVisible"
                                            />
                                        </ul>
                                    </li>
                                </ul>

                                <ul class="mt-auto">
                                    <SidebarItem
                                        v-for="item in bottomNav.filter(f => f.canSee())"
                                        :key="item.name"
                                        :item="item"
                                        :is-sidebar-visible="isSidebarVisible"
                                        class="-mx-2"
                                    />
                                    <SidebarDropdown />
                                    <SidebarItem
                                        :item="{
                                                name: user.name,
                                                href: '/company'
                                            }"
                                        :is-sidebar-visible="isSidebarVisible"
                                        class="-mx-2"
                                    >
                                        <template #icon>
                                            <BaseAvatar
                                                class="h-6 w-6 rounded-full"
                                                :src="user.tenant.avatar"
                                                :name="user.tenant.name"
                                            />
                                        </template>
                                    </SidebarItem>
                                </ul>
                            </nav>
                        </div>
                    </DialogPanel>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div
        class="hidden transition-transform duration-400 ease-in-out lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:flex-col group/sidebar"
        :class="{ 'hidden-sidebar !lg:w-14': !isSidebarVisible, 'lg:w-60': isSidebarVisible }">
        <div
            class="absolute rounded-full top-20 opacity-0 group-hover/sidebar:opacity-100"
            :class="{
                    'right-[20px]': isSidebarVisible,
                    'left-[10px] ': !isSidebarVisible
                }"
        >
            <Button
                button-size="small"
                variant="secondary"
                @click="toggleSidebar"
            >
                <ArrowLeftIcon v-if="isSidebarVisible" class="w-4 h-4"></ArrowLeftIcon>
                <ArrowRightIcon v-if="!isSidebarVisible" class="w-4 h-4"></ArrowRightIcon>
            </Button>
        </div>
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div
            class="flex grow flex-col gap-y-5 overflow-y-auto sidebar-background overflow-x-visible"
            :class="{
                    'px-2': !isSidebarVisible,
                    'px-6': isSidebarVisible
                }"
        >
            <Link href="/rooms" class="flex mt-9 shrink-0 items-center"
                  :class="[isSidebarVisible ? 'justify-start' : 'justify-center']"
            >
                <img v-if="isSidebarVisible" class="h-7 w-auto" src="/images/logo.png" alt="Roombriks"/>
                <img v-if="!isSidebarVisible" class="h-7 w-auto" src="/images/logo_symbol.png" alt="Roombriks"/>
            </Link>
            <nav class="flex flex-1 flex-col mt-8">
                <ul role="list" class="flex flex-1 flex-col gap-y-7">
                    <li>
                        <ul role="list" class="-mx-2 space-y-1">
                            <SidebarItem
                                v-for="item in navigation"
                                :key="item.name"
                                :item="item"
                                :isSidebarVisible="isSidebarVisible"
                            />
                        </ul>
                    </li>
                    <div class="mt-2 mt-auto mb-2">
                        <SidebarItem
                            v-for="item in bottomNav.filter(f => f.canSee())"
                            :key="item.name"
                            :item="item"
                            :isSidebarVisible="isSidebarVisible"
                            class="-mx-2"
                        />
                        <li class="-mx-2">
                            <SidebarDropdown  :isSidebarVisible="isSidebarVisible"/>
                        </li>
                    </div>
                </ul>
            </nav>
        </div>
    </div>
</template>
<script setup>
import { XMarkIcon } from "@heroicons/vue/24/outline/index.js";
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import BaseAvatar from "../forms/BaseAvatar.vue";
import { Link, usePage } from "@inertiajs/vue3";
import { nextTick, ref } from "vue";
import BarChartIcon from "@/Layouts/icons/BarChartIcon.svg";
import RoomsIcon from "@/Layouts/icons/RoomsIcon.svg";
import LibraryBig from "@/Layouts/icons/LibraryBig.svg";
import AccountIcon from "@/Layouts/icons/AccountIcon.svg";
import AccountPlanner from "@/Layouts/icons/AccountPlanner.svg";
import { useAuth } from "../../hooks/useAuth.js";
import SidebarItem from "./SidebarItem.vue";
import Button from "../../tiptap/components/ui/button/Button.vue";
import { ArrowLeftIcon, ArrowRightIcon, CreditCardIcon, UsersIcon } from "lucide-vue-next";
import SidebarDropdown from './SidebarDropdown.vue';

const page = usePage();
const { user, isAdmin } = useAuth()

const navigation = ref([
    // {
    //     name: 'Dashboard',
    //     href: '/', icon: BarChartIcon, current: page.url === '/',
    //     canCreate: false,
    // },
    {
        name: 'Rooms',
        href: '/rooms',
        icon: RoomsIcon,
        current: page.url.startsWith('/rooms') || page.url.startsWith('/room-detail') || page.url.startsWith('/room-view'),
        canCreate: true,
        isVisible: page.url.startsWith('/rooms') || page.url.startsWith('/templates') || page.url.startsWith('/room-detail') || page.url.startsWith('/room-view'),
    },
    {
        name: 'Library',
        href: '/content', icon: LibraryBig, current: page.url.startsWith('/content'),
    },
    {
        name: 'Accounts',
        href: '/accounts',
        icon: AccountIcon,
        current: page.url.startsWith('/accounts') && !page.url.startsWith('/accounts-planner'),
        canCreate: true,
    },
    // {
    //     name: 'Account Planner',
    //     href: '/accounts-planner', icon: AccountPlanner, current: page.url.startsWith('/accounts-planner'),
    //     canCreate: true,
    // },
])

const bottomNav = [
    {
        name: 'Invite Members',
        href: '/invitations', icon: UsersIcon, current: page.url.startsWith('/invitations'),
        canCreate: true,
        canSee: () => isAdmin()
    },
]


const sidebarOpen = defineModel()
const isSidebarVisible = defineModel('sidebarVisible', {
    type: Boolean,
})

async function toggleSidebar() {
    isSidebarVisible.value = !isSidebarVisible.value;
    await nextTick()
    localStorage.setItem('isSidebarVisible', isSidebarVisible.value);
}
</script>
<style lang="scss">
.sidebar-background {
    @apply bg-blue-50;
}
</style>
