<template>
    <RoomOptionsEmailsInput
        @onInsertMember="onInsertMember"
    />
    <form @submit.prevent="saveShareOptions">
        <p class="mt-4 label">People with access</p>
        <div class="flex flex-col">
            <div v-for="(a, index) in access" class="flex justify-between items-center text-sm">
                <div class="flex gap-2 items-center">
                    <FormField v-slot="{ value, handleChange }" type="checkbox" :name="'access[' + index + '].notify'">
                        <FormItem v-auto-animate class="flex items-center space-x-2">
                            <FormControl class="flex items-center">
                                <Checkbox :checked="value" @update:checked="(e) => {
                                            handleChange(e);
                                            a.notify = e;
                                        }"/>
                            </FormControl>
                            <div class="!mt-0 flex w-full justify-between">
                                <div class="mt-1">
                                    <FormMessage/>
                                </div>
                            </div>
                        </FormItem>
                    </FormField>
                    <div>{{ a.email }}</div>
                </div>
                <div class="flex gap-2 items-center">
                    <FormField v-slot="{ componentField }" :name="'access[' + index + '].role'">
                        <FormItem v-auto-animate class="col-span-6">
                            <FormControl>
                                <Select
                                    v-bind="componentField"
                                    :defaultValue="access[index].role"
                                    @update:modelValue="value => {
                                                a.role = roleOptions.find(r => r.value === value).value;
                                            }"
                                >
                                    <FormControl>
                                        <SelectTrigger class="gap-2 border-0">
                                            <SelectValue placeholder="Role"/>
                                        </SelectTrigger>
                                    </FormControl>
                                    <SelectContent  style="z-index: 99999">
                                        <SelectGroup>
                                            <SelectItem selected="true" v-for="role in roleOptions" :value="role.value">
                                                {{ role.name }}
                                            </SelectItem>
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    </FormField>

                    <a href="" @click="(e) => {
                                e.preventDefault();
                                access.splice(index, 1)
                            }">
                        <i class="pi pi-times" style="color: #ccc; font-size: 12px"></i>
                    </a>
                </div>
            </div>
            <div class="gap-2 text-sm flex items-center">
                <FormField v-slot="{ value, handleChange }" type="checkbox" name="notify">
                    <FormItem v-auto-animate class="flex items-center space-x-2">
                        <FormControl class="flex items-center">
                            <Checkbox
                                :checked="value"
                                :disabled="access.every(a => !a.notify)"
                                @update:checked="(e) => {
                                            handleChange(e);
                                            a.notify = e;
                                        }"
                            />
                        </FormControl>
                        <div class="!mt-0 flex w-full justify-between">
                            <div class="mt-1">
                                <FormMessage/>
                            </div>
                        </div>
                    </FormItem>
                </FormField>

                <p>Notify people</p>
            </div>

            <div v-if="form.notify">
                <FormField v-slot="{ componentField }" name="notifyMessage">
                    <FormItem v-auto-animate class="col-span-6">
                        <FormControl>
                            <FormLabel>Message</FormLabel>
                            <Textarea v-bind="componentField" />
                        </FormControl>
                        <FormMessage/>
                    </FormItem>
                </FormField>
            </div>
        </div>

        <FormField v-slot="{ componentField }" name="shareType" >
            <FormItem v-auto-animate class="col-span-6">
                <FormLabel>General access</FormLabel>
                <FormControl>
                    <Select
                        v-bind="componentField"
                    >
                        <FormControl>
                            <SelectTrigger class="h-14">
                                <SelectValue placeholder="Access type" class="p-2"/>
                            </SelectTrigger>
                        </FormControl>
                        <SelectContent  style="z-index: 99999">
                            <SelectGroup>
                                <SelectItem v-for="model in shareOptions" :value="model.value" class="w-full">
                                    <div class="flex flex-row w-full items-center gap-2">
                                        <div
                                            class="rounded-full w-4 h-4 p-4 flex items-center justify-center"
                                            :class="model.iconColor"
                                        >
                                            <i v-if="model.icon" :class="`text-white pi ${model.icon}`"/>

                                            <div>
                                                <component v-if="model.svgIcon" :is="model.svgIcon"
                                                           class="w-4 text-white h-4"/>
                                            </div>
                                        </div>

                                        <div class="flex flex-col gap-2 items-start">
                                            <div>{{ model.name }}</div>
                                            <p class="text-xs text-gray-500"> {{ model.description }}</p>
                                        </div>
                                    </div>

                                </SelectItem>
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </FormControl>
                <FormMessage/>
            </FormItem>
        </FormField>

        <div v-if="form.shareType === 'restricted'">
            <div class="mt-4 label">Additional security</div>

            <div class="flex gap-2 mt-4">
                <FormField v-slot="{ value, handleChange }" type="checkbox" name="restricted_email">
                    <FormItem v-auto-animate class="flex items-center space-x-2">
                        <FormControl class="flex items-center">
                            <Checkbox :checked="value" @update:checked="handleChange"/>
                        </FormControl>
                        <div class="!mt-0 flex w-full justify-between">
                            <div class="mt-1">
                                <FormLabel class="pt-0">Verify via magic link</FormLabel>
                                <FormMessage/>
                            </div>
                        </div>
                    </FormItem>
                </FormField>
            </div>

            <div class="flex gap-2 mt-4">
                <FormField v-slot="{ value, handleChange }" type="checkbox" name="restricted_password">
                    <FormItem v-auto-animate class="flex items-center space-x-2">
                        <FormControl class="flex items-center">
                            <Checkbox :checked="value" @update:checked="handleChange"/>
                        </FormControl>
                        <div class="!mt-0 flex w-full justify-between">
                            <div class="mt-1">
                                <FormLabel class="pt-0">Add password protection</FormLabel>
                                <FormMessage/>
                            </div>
                        </div>
                    </FormItem>
                </FormField>
            </div>

            <div v-if="form.restricted_password" class="ml-10 flex gap-2 items-end">
                <FormField v-slot="{ componentField }" name="password" class="w-full">
                    <FormItem v-auto-animate class="" >
                        <FormLabel>Password required to view the room</FormLabel>
                        <FormControl>
                            <div class="flex">
                                <Input :type="passwordType" v-bind="componentField" />
                            </div>
                        </FormControl>
                        <FormMessage/>
                    </FormItem>
                </FormField>

                <Button
                    variant="secondary"
                    size="icon"
                    @click="passwordType = passwordType === 'password' ? 'text' : 'password'">
                    <EyeIcon v-if="passwordType === 'password'" class="w-4" style="height:22px; height: 22px"></EyeIcon>
                    <EyeOffIcon v-else class="w-4 h-4"></EyeOffIcon>
                </Button>
            </div>
        </div>
        <div class="flex gap-2 justify-end mt-10">
            <Button
                size="small"
                variant="secondary"
                @click="copyToClipboard">
                <ClipboardIcon class="w-4 h-4"></ClipboardIcon>
                <span>Copy link</span>
            </Button>
            <Button
                size="small"
                type="submit"
            >
                <CheckIcon class="w-4 h-4"/>
                <span>Done</span>
            </Button>
        </div>
    </form>
</template>
<script setup>
import { computed, reactive, ref, watch } from 'vue';
import { CheckIcon, ClipboardIcon, EyeIcon, EyeOffIcon, GlobeLock } from 'lucide-vue-next'
import { usePage } from '@inertiajs/vue3';
import { displayErrorMessagesAsToast } from '../../../utils/errorUtils.js';
import * as yup from 'yup';
import { Checkbox } from '@/Components/ui/checkbox'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/Components/ui/select'
import Button from '../../../tiptap/components/ui/button/Button.vue';
import { useTipTapStore } from '@/tiptap/stores/useTipTapStore';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/Components/ui/form'
import { Input } from '@/Components/ui/input';
import { Textarea } from '@/Components/ui/textarea'
import useValidatedForm from '../../../hooks/useValidatedFormNew';
import { useToast } from 'primevue/usetoast';
import RoomOptionsEmailsInput from './RoomOptionsEmailsInput.vue';
import { router } from "@inertiajs/vue3"
const page = usePage();

const room = ref(page.props.room);
const tipTapStore = useTipTapStore();
const roleOptions = ref([
    {name: 'Collaborator', value: 'collaborator'},
    {name: 'Viewer', value: 'viewer'},
]);
const passwordType = ref('password');
const emit = defineEmits(['save']);
const props = defineProps({
    onClose: {
        type: Function,
    }
});
const saving = ref(false);
const toast = useToast();
tipTapStore.$subscribe(function (state) {
    saving.value = tipTapStore.saving;
})

const access = ref(
    page.props.room.access.map((a) => ({
            ...a,
            role: roleOptions.value.find((o) => {
                return o.value === a.role
            }).value
        })
    )
);

const shareOptions = ref([
    {
        value: 'hidden',
        name: 'Hidden',
        description: 'Not visible to anyone',
        icon: 'pi pi-eye-slash',
        iconColor: 'bg-red-200'
    },
    {
        value: 'anyone',
        name: 'Anyone with the link',
        description: 'Email gate, no verification',
        icon: 'pi pi-link',
        iconColor: 'bg-purple-400'
    },
    {
        value: 'restricted',
        name: 'Restricted Email',
        description: 'Only people with access can view the room',
        iconColor: 'bg-cyan-400',
        svgIcon: GlobeLock,
    },
    {
        value: 'public',
        name: 'Public',
        description: 'No email required to view the room. Comments and message removed',
        icon: 'pi-globe',
        iconColor: 'bg-green-400'
    },
]);

const initialValues = reactive({
    members: [],
    shareType: shareOptions.value.find((o) => o.value === room.value.share_type).value,
    restricted_email: !!room.value.restricted_email,
    restricted_password: !!room.value.restricted_password,
    password: room.value.password,
    notify: false,
    notifyMessage: ''
});

const form = useValidatedForm(initialValues, {})

function saveShareOptions() {
    const data = {...form.data()};

    if (data.shareType === 'restricted' && !data.restricted_password && !data.restricted_email) {
        toast.add({
            severity: 'error',
            summary: 'Security required',
            detail: 'Please select a security option',
            life: 3000
        });

        return;
    }

    if (data.shareType === 'restricted' && data.restricted_password && !data.password) {
        toast.add({severity: 'error', summary: 'Password required', detail: 'Please add a password', life: 3000});

        return;
    }

    form.transform((data) => {
        data.room_id = room.value.id;
        data.share_type = data.shareType;
        data.members = access.value.map((a) => {
            return {
                email: a.email,
                role: a.role,
                notify: a.notify
            }
        });

        delete data.shareType;

        return data;
    }).post(`/rooms/${room.value.ulid}/share`, {
        preserveState: true,
        onSuccess: () => {
            router.reload();

            form.notify = false;

            access.value = page.props.room.access.map((a) => ({
                    ...a,
                    role: roleOptions.value.find((o) => {
                        return o.value === a.role
                    }),
                    notify: false,
                })
            );

            emit('onClose');
        },
        onError: (err) => {
            displayErrorMessagesAsToast(err, toast)
        },
    });
}

async function onInsertMember(value) {
    const emails = value.split(',');
    form.members = [];

    const schema = yup.object().shape({
        email: yup.string().email('This must be a valid email').required('Email is required'),
    });

    emails.forEach((email) => {
        const emailToValidate = {email: email};
        schema.validate(emailToValidate)
            .then(function () {
                access.value.push({
                    email: email,
                    role: roleOptions.value[1].value,
                    notify: false,
                });
            })
            .catch(function (err) {
                //emailForm.vee.setFieldError('email', err.errors[0])
            });
    });
}

const copyToClipboard = async () => {
    try {
        console.log('room', room);
        const url = `${window.location.origin}/a/${room.value.account?.slug || "n"}/${room.value.ulid}`;
        console.log('URL', url);
        await navigator.clipboard.writeText(url);

        toast.add({severity: 'success', summary: '', detail: 'Link copied to clipboard', life: 3000});
    } catch (error) {
        console.error('Failed to copy to clipboard:', error);
    }
};

</script>
